import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, Typography } from '@mui/material';

interface AddParamProps {
    onParamAddRequest : (key : string, value : string) => Promise<boolean>
};


export default function AddParamBox(props : AddParamProps) {
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState<string>("");
  const [value, setValue] = React.useState<string>("");
  const [addingParam, setAddingParam] = React.useState<boolean>(false);

  React.useEffect( () => {
    setAddingParam(false);
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddParam = () => {
    if(props.onParamAddRequest) {
        setAddingParam(true);
        props.onParamAddRequest(key, value).then( (res) => {
            if(res) handleClose();
            else alert("Could not add param");
        }).catch((error) => {
            alert("Could not add param");
        }).finally( () => {
            setAddingParam(false);
        })
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Param
      </Button>
      <Dialog open={open} >
        <DialogTitle>Configure New Param</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the key and value as strings which you would like to configure for this app:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="key"
            label="Param Key"
            type="text"
            fullWidth
            variant="standard"
            value={key}
            onChange={(evt) => {
                setKey(evt.target.value);
            }}
            disabled={addingParam}
          />
          <TextField
            margin="dense"
            id="value"
            label="Param Value"
            type="text"
            fullWidth
            variant="standard"
            value={value}
            onChange={(evt) => {
                setValue(evt.target.value);
            }}
            disabled={addingParam}
          />
          {addingParam && (
              <Box>
                  <CircularProgress />
                  <Typography variant="caption">Adding Params</Typography>
              </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={addingParam}>Cancel</Button>
          <Button onClick={handleAddParam} disabled={addingParam}>Add Param</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
