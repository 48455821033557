import { StarknetWindowObject } from "@argent/get-starknet/dist/extension.model";
import { hash } from 'starknet';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, CircularProgress, Link, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ByoaApp } from "../types/ByoaApp";
import { BrandColors } from "../utils/constants";
import { reformatIPFS } from "../utils/reformatIPFS";
import { L2AppData } from "../types/L2App";
import AddParamBox from "./AddParam";
import { str_to_felt_as_string } from "../utils/str_to_felt";

interface Props {
    app?: ByoaApp;
    l2Data?: L2AppData;
    starkIsConnected: boolean;
    isInstalled: boolean;
    swo?: StarknetWindowObject;
    onInstalled?: () => void;
    contractDetails: any;
};

export default function ByoaCard(props : Props) {
    const [appImageURI, setAppImageURI] = useState<string|undefined>(undefined);
    const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);

    const [isInstalling, setIsInstalling] = useState<boolean>(false);
    const [transactionStatus, setTransactionStatus] = useState<string>("");

    useEffect( () => {
        setTransactionStatus("");
        setIsInstalling(false);
        setHasLoadingError(false);
        setAppImageURI(undefined);
    }, [])

    useEffect( () => {
        setHasLoadingError(false);
        // Update data for the app such as fetching the metadata from the tokenURI
        axios.get(reformatIPFS(props.app?.tokenURI as string)).then( (data) => {
            if(data.status === 200) {
                let image = data.data['image'];
                if(image && image !== "") {
                    setAppImageURI(reformatIPFS(image));
                    console.log(reformatIPFS(image));
                }
            } else {
                console.log("Status was not 200 to fetch data from IPFS");
            }

        }).catch( (error) => {
            alert("Error fetching metadata for byoa app");
        })
    }, [props.app]);

    const uninstallApp = async () => {
        try {
            setIsInstalling(true);
            if(props.swo) {
                let res = await props.swo.account?.execute({
                    contractAddress: props.contractDetails.address,
                    entrypoint:   "toggle_install_app_by_index",
                    calldata: [`${props.l2Data?.AppIndex}`, '0']
                })
                /*
                let res = await props.swo.signer?.invokeFunction(
                    props.contractDetails.address,
                    hash.getSelectorFromName("toggle_install_app_by_index"),
                    [`${props.l2Data?.AppIndex}`, '0']
                )
                */

                setTransactionStatus("WAITING FOR UNINSTALL");
                if(res) {
                    let iv = setInterval(async () => {
                      let txData = await props.swo?.provider.getTransactionStatus(`${res?.transaction_hash}`);
                      if(txData) {   
                          console.log(txData.tx_status);
                          setTransactionStatus(`Uninstall ${txData.tx_status}`);
                          if(txData.tx_status === 'PENDING' || txData.tx_status === 'REJECTED') {
                              clearInterval(iv);
                              if(props.onInstalled) {
                                  props.onInstalled();
                              }
                              
                              setTransactionStatus("");
                              setIsInstalling(false);
                          }
                      } else {
                          console.log("Failed to receive transaction data for ", res?.transaction_hash)
                      }
                    }, 5000)
                }
            } else {
                console.log("Props.swo not avail")
                setIsInstalling(false);
            }
        } catch (error) {
            alert(`Error Install App: ${error}`)
        }
    }

    const installApp = async () => {
        try {
          setIsInstalling(true);
          if(props.swo) {
              let res = await props.swo.account?.execute({
                  contractAddress: props.contractDetails.address,
                  entrypoint: "add_app_id",
                  calldata: [`${props.app?.id}`]
              })
              /*
            let res = await props.swo.signer?.invokeFunction(
              props.contractDetails.address,
              hash.getSelectorFromName("add_app_id"),
              [`${props.app?.id}`]
            );
            */
            setTransactionStatus("WAITING");
    
            if(res) {
              let iv = setInterval(async () => {
                let txData = await props.swo?.provider.getTransactionStatus(`${res?.transaction_hash}`);
                if(txData) {   
                    console.log(txData.tx_status);
                    setTransactionStatus(txData.tx_status);
                    if(txData.tx_status === 'PENDING' || txData.tx_status === 'REJECTED') {
                        clearInterval(iv);
                        if(props.onInstalled) {
                            props.onInstalled();
                        }
                        
                        setTransactionStatus("");
                        setIsInstalling(false);
                    }
                } else {
                    console.log("Failed to receive transaction data for ", res?.transaction_hash)
                }
              }, 5000)
            }
          } else {
            setIsInstalling(false);
          }
        } catch (error) {
          alert(`Error Install App: ${error}`)
        } finally {
            
        }
      };

    const addParamToApp = async (key : string, value : string) : Promise<boolean> => {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                if(props.swo) {
                    let res = await props.swo.account?.execute({
                        contractAddress: props.contractDetails.address,
                        entrypoint: "add_param",
                        calldata: [`0`, // We don't need user address, need to remove this
                        `${props.l2Data?.AppIndex}`, 
                        str_to_felt_as_string(key), 
                        str_to_felt_as_string(value)
                        ]
                    })
                    /*
                    let res = await props.swo.signer?.invokeFunction(
                            props.contractDetails.address,
                            hash.getSelectorFromName("add_param"),
                            [`0`, // We don't need user address, need to remove this
                            `${props.l2Data?.AppIndex}`, 
                            str_to_felt_as_string(key), 
                            str_to_felt_as_string(value)
                            ]
                        );
                    */
                    setTransactionStatus("WAITING");
            
                    if(res) {
                        let iv = setInterval(async () => {
                            let txData = await props.swo?.provider.getTransactionStatus(`${res?.transaction_hash}`);
                            if(txData) {   
                                if(txData.tx_status === 'PENDING' || txData.tx_status === 'REJECTED') {
                                    clearInterval(iv);
                                    resolve(txData.tx_status === 'PENDING')
                                    if(props.onInstalled) {
                                        props.onInstalled();
                                    }
                                }
                            } else {
                                resolve(false)
                            }
                        }, 5000);
                    }
                } else {
                    resolve(false);
                }
            } catch (error) {
                reject(`Unable to set parameter ${error}`)
            } finally {
                
            }
        })
    }

    return (
            <Card style={{boxShadow: 'none'}}>
                <CardMedia 
                   component="img"
                   image={appImageURI || "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/610b3e398e603328b9d19d82_mellow-2797.png"}
                   alt="App Image" 
                   style={{borderRadius: 10}}
                />
                <CardContent>
                    {props.app && (
                        <Box>
                            <Box>
                                <Typography variant="h6">{props.app.name}</Typography>
                                <Typography variant="caption" >{props.app.description}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption">Details:</Typography>
                                <Box>
                                    <Chip label={`id: ${props.app.id}`} variant="filled"/>
                                    <Chip label={`author: ${props.app.owner.slice(0,5)}...${props.app.owner.slice(37)}`} variant="filled"/>
                                    <Link href={reformatIPFS(props.app.tokenURI as string)} style={{color: 'black'}}>  Token URI</Link>
                                </Box>
                                
                            </Box>
                        </Box>
                    )}
                    {props.l2Data && (
                        <Box>
                            <Typography>{props.l2Data.Params.length} Params Configured</Typography>
                            {props.l2Data.Params.length > 0 && (
                                <ul>
                                    {props.l2Data.Params.map((param) => {
                                        return (
                                            <li key={`app-param-${param.ID}`}>{param.ID} = {param.Value}</li>
                                        )
                                    })}
                                </ul>
                                    
                            )}
                            <AddParamBox 
                                onParamAddRequest={(key : string, value : string) : Promise<boolean> => {
                                    return addParamToApp(key, value);
                                }}
                            />
                        </Box>
                    )}
                </CardContent>
                <CardActions style={{borderTopColor: BrandColors.GREEN, borderTopWidth: 1, borderTopStyle: 'solid'}}>
                    {props.isInstalled && props.l2Data && (
                        <Box>
                            <Button size="small" onClick={() => {
                                uninstallApp();
                            }}
                                disabled={isInstalling}
                            >
                                Uninstall
                            </Button>
                        </Box>
                    )}
                    {!props.isInstalled && (
                        <Box style={{display: 'block'}}>
                            <Button size="small" variant="outlined" style={
                                {backgroundColor: 'black',
                                color: BrandColors.GREEN, fontWeight: 'bold'}
                            } onClick={() => {
                                installApp();
                            }}
                                disabled={isInstalling}
                            >{isInstalling ? 'Installing' : 'Install'}</Button><br/>
                        </Box>
                    )}
                    {isInstalling && (
                        <Box>
                            <Typography variant="caption">Install Status: {transactionStatus}
                                <CircularProgress />
                            </Typography>
                        </Box>
                    )}
                    {!props.starkIsConnected && (
                        <Box style={{display: 'block'}}>
                            <Typography variant="caption">* Connect your wallet to install.</Typography>
                        </Box>
                    )}
                </CardActions>
            </Card>
    )
}