import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ByoaSDK } from 'byoa-sdk';


function Resources() {
    return (
        <Box style={{minHeight: '80vh'}}>
            <Container>
                <h1>Resources</h1>
                <Typography variant="caption">
                    Below are some resources for you to become more familiar with the Mallows, byoa, and StarkWare L2 ecosystems.
                </Typography>
                <h2>Mallows / byoa</h2>
                <h3>Mallows</h3>
                <ul>
                    <li><a href="https://mallows.xyz" target="_blank">mallows.xyz</a> - home page for the Mallows project</li>
                    <li><a href="https://mint.mallows.xyz" target="_blank">Mint a Mallow</a></li>
                    <li><a href="https://discord.gg/64bN2cNuJ6" target="_blank">Mallows Discord</a></li>
                    <li><a href="https://twitter.com/mallowsxyz" target="_blank">@MallowsXYZ Twitter</a></li>
                    <li><a href="https://www.mallows.xyz/mallows-l2" target="_blank">Learn more about Mallows/byoa L2 Architecture</a></li>
                </ul>

                <h3>byoa</h3>
                <ul>
                    <li><a href="byoa.org" target="_blank">byoa.org</a> - home page for the byoa project</li>
                    <li><a href="https://github.com/web3-byoa/whitepaper/blob/main/byoa%20whitepaper.pdf" target="_blank">byoa Whitepaper</a></li>
                </ul>

                <h3>Repositories</h3>
                <ul>
                    <li><a href="https://github.com/web3-byoa" target="_blank">web3-byoa organization</a> - Github organization for all repositories</li>
                    <li><a href="https://github.com/web3-byoa/react-byoa-sdk" target="_blank">byoa-sdk</a></li>
                    <li><a href="https://github.com/web3-byoa/starknet_l2_contract" target="_blank">L2 StarkNet contract</a></li>
                    <li><a href="https://github.com/web3-byoa/app-store-contract" target="_blank">L1 App Registry contract</a></li>
                    <li><a href="https://www.starknetjs.com/" target="_blank">Starknet.js</a> - repository to interact with StarkNet</li>
                </ul>

                <h2>StarkWare</h2>
                <ul>
                    <li><a href="https://t.co/1l9GiqE7uz" target="_blank">StarkWare homepage</a></li>
                    <li><a href="https://www.cairo-lang.org/" target="_blank">Cairo Lang</a></li>
                    <li><a href="https://starknet.io/" target="_blank">StarkNet resources</a></li>
                    <li><a href="https://t.co/klHVDhQokP" target="_blank">Discord</a></li>
                    <li><a href="https://twitter.com/StarkWareLtd" target="_blank">Twitter</a></li>
                    <li><a href="https://voyager.online/" target="_blank">Voyager</a></li>
                    <li><a href="https://github.com/gakonst/awesome-starknet" target="_blank">Awesome Starknet</a></li>
                    <li><a href="https://voyager.online/contract/0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4" target="_blank">Starknet Alpha Mainnet Contract for BYOA - <code>0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4</code> </a></li>
                    
                </ul>

                <h2>Argent</h2>
                <ul>
                    <li><a href="https://www.argent.xyz/" target="_blank">argent.xyz</a> - Layer 2 Compatible Wallet</li>
                    <li><a href="https://www.argent.xyz/blog/argent-x-starknet/" target="_blank">ArgentX</a></li>
                </ul>
            </Container>
            
        </Box>
    )
}

export default Resources;
