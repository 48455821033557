import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ByoaSDK } from 'byoa-sdk';


function Launch() {
    return (
        <Box style={{minHeight: '80vh'}}>
            <Container>
                <h1>App Launch Page for Starknet Usage</h1>
                <p>On this page you can utilize your byoa apps via the byoa-sdk.</p>
                <h2>Getting Started</h2>
                <ul>
                    <li>Make sure your wallet is connected to Mainnet</li>
                    <li>Connect your wallet (may take up to 30 seconds to fetch your installed byoa apps)</li>
                    <li>Click the blue byoa-sdk button below</li>
                    <li>Click an app to launch</li>
                    <li>Click the app launch icon again to toggle the size</li>
                </ul>
                <ByoaSDK 
                    starknetConfiguration={{
                        address: '0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4',
                        network: 'mainnet'
                    }}
                />
            </Container>
            
        </Box>
    )
}

export default Launch;
