export const reformatIPFS = (ipfs : string) => {
    if(ipfs.indexOf("ipfs://") >= 0) {
        let baseIPFS = ipfs.split("ipfs://")[1];
        if(baseIPFS.indexOf("ipfs/") >= 0) {
            baseIPFS = baseIPFS.split("ipfs/")[1];
        }
        return `https://cloudflare-ipfs.com/ipfs/${baseIPFS}`;
    } else {
        return ipfs;
    }
}