import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import '../App.css';

import { getStarknet } from '@argent/get-starknet';
import { Alert, Box, Button, CircularProgress, Container, createTheme, Grid, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';
import HideAppBar from '../components/HideOnScrollAppBar';
import { StarknetWindowObject } from '@argent/get-starknet/dist/extension.model';
import { hash } from 'starknet';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { BrandColors } from '../utils/constants';
import ByoaCard from '../components/ByoaCard';
import { ByoaApp } from '../types/ByoaApp';
import { L2AppData } from '../types/L2App';
import { felt_to_str } from '../utils/str_to_felt';

var byoaAbi = require('../abi/Byoa.json');
const byoaContractAddress = `0x8f15c4ea6ce3fbfc5f7402c5766fc94202704161`;
const providerNetwork = `https://eth-mainnet.alchemyapi.io/v2/N9hhfuCL7V9y5dXCD5AOddGs-zVIyYc4`;


const contractDetails = {
  'goerli': {
    address: '0x0602ddda312f52dac2609a4273c7a731b0e6e4b3aee83c41c6f3b9aa9ac02ae5'
  },
  'mainnet': {
    address: '0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4'
  }
};

const chosenNetwork = 'mainnet';

interface HomeProps {
  swo?: StarknetWindowObject;
  starkWalletAddress?: String;
  starkIsConnected?: boolean;
  onConnect?: (swo : any, addr : any, sic : any) => void;
}

function HomePage(props: HomeProps) {
  const [swo, setSWO] = useState<StarknetWindowObject | undefined>(undefined);
  const [starkWalletAddress, setStarkWalletAddress] = useState<String | undefined>(undefined);
  const [starkIsConnected, setStarkIsConnected] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [appData, setAppData] = useState<L2AppData[]>([]);
  const [apps, setApps] = useState<ByoaApp[]>([]);
  const [isLoadingL1Data, setIsLoadingL1Data] = useState<boolean>(false);
  const [l2AppIdLUT, setL2AppIdLUT] = useState<any>({});

  const refreshPageData = async () => {
    fetchAvailableApps();
  };

  useEffect( () => {
    refreshPageData();
  }, []);

  useEffect( () => {
    if(props.swo && swo !== props.swo) {
      setSWO(props.swo);
    }
    if(props.starkWalletAddress && starkWalletAddress !== props.starkWalletAddress) {
      setStarkWalletAddress(props.starkWalletAddress);
    }
    if(props.starkIsConnected && starkIsConnected !== props.starkIsConnected) {
      setStarkIsConnected(props.starkIsConnected);
    }
  }, [props.swo, props.starkWalletAddress, props.starkIsConnected]);

  useEffect( () => {
    if(swo === undefined) return;
    if(swo?.isConnected !== starkIsConnected) {
      setStarkIsConnected(swo?.isConnected);
    }
  }, [swo?.isConnected]);

  useEffect( () => {
    loadL2AppData();
  }, [starkIsConnected]);

  const connectArgentWallet = async () => {
    try{
      const starknet = getStarknet()
      setSWO(starknet);
      
      const [userWalletContractAddress] = await starknet.enable() // may throws when no extension is detected
      if(userWalletContractAddress.length > 0) {
        setStarkWalletAddress(userWalletContractAddress);
        setStarkIsConnected(starknet.isConnected);
        if(props.onConnect) {
          props.onConnect(swo, userWalletContractAddress, starknet.isConnected);
        }
      }

    } catch (error) {
      console.log(`Got Starknet Error: `, error);
    }
  };

  const fetchAvailableApps = async () => {
    setIsLoadingL1Data(true);
    let w3 = new Web3(providerNetwork);
    try {
      let contract = new w3.eth.Contract(byoaAbi['abi'], byoaContractAddress);
      let appIds = await contract.methods.getAppIds().call();

      let allApps = [];
      for(let i = 0; i < appIds.length; i ++) {
        let id : Number = parseInt(appIds[i]);
        // Get the details
        let appDetails = await contract.methods.getAppDetailsById(id).call();
        
        let app : ByoaApp = {
          id: id, 
          name: appDetails[0],
          description: appDetails[1],
          tokenURI: appDetails[2],
          owner: appDetails[3],
          price: parseInt(appDetails[4]),
          address: byoaContractAddress,
          version: 'beta v0.1'
        };

        allApps.push(app);
      }

      setApps(allApps)
      
    } catch( error ) {
      console.log(`Error fetching apps: ${error}`) ;
    } finally {
      setIsLoadingL1Data(false);
    }
  };

  const loadL2AppData = async () => {
    setIsLoadingData(true);
    if(swo) {
      try {

        let tAppData : L2AppData[] = [];
        
        let getAppLenResult = await swo.provider?.callContract({
          contractAddress: contractDetails[chosenNetwork].address,
          entrypoint: "get_app_len",
          calldata: [ethers.BigNumber.from(swo.selectedAddress).toString()]
        });
        let numberOfApps = ethers.BigNumber.from(getAppLenResult.result[0]).toNumber();
        
        let tL2AppIdLUT = {};
        for(let i = 0; i < numberOfApps; i ++) {
          let getAppArrayDataByIndexResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_array",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });
          let appIdAtIndex = ethers.BigNumber.from(getAppArrayDataByIndexResult.result[0]).toNumber();

          let isInstalledResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_installation",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });

          let isInstalled = (ethers.BigNumber.from(isInstalledResult.result[0]).toNumber() === 1);
          if(!isInstalled) {
            continue;
          }

          let appParamCountResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_param_count",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });

          let configuredAppParams : any = [];
          for(let j = 0; j < ethers.BigNumber.from(appParamCountResult.result[0]).toNumber(); j ++) {
            let appParamValuesByIndexResult = await swo.provider?.callContract({
              contractAddress: contractDetails[chosenNetwork].address,
              entrypoint: "get_app_param_value_array",
              calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`, `${j}`]
            });
            
            configuredAppParams.push({
              ID: felt_to_str(appParamValuesByIndexResult.result[0]),
              Value: felt_to_str(appParamValuesByIndexResult.result[1]),
            });

          }

          // Find the L1 App Details
          for(var z = 0; z < apps.length; z ++) {
            if(apps[z].id === appIdAtIndex) {
              tAppData.push({
                AppId: appIdAtIndex,
                AppIndex: i,
                Params: configuredAppParams,
                Status: 'ACCEPTED',
                IsInstalled: ethers.BigNumber.from(isInstalledResult.result[0]).toNumber() === 1,
                ByoaApp: apps[z]
              });
              break;
            }
          }
          
          //@ts-expect-error
          tL2AppIdLUT[`${appIdAtIndex}`] = true;
        }

        setAppData(tAppData);
        setL2AppIdLUT(tL2AppIdLUT);

      } catch (error) {
        console.log("Error with resz", error)
      } finally {
        setIsLoadingData(false);
      }
    } else {
      setIsLoadingData(false);
    }
  };

  return (
      <Box>
        
        <Box style={{backgroundColor: BrandColors.BLACK, marginBottom: 50}}>
          <Box style={{
            padding: 30,
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Box style={{flex: 1, color: BrandColors.GREEN, textAlign: 'right'}} >
              <Typography variant="h1" style={{fontWeight: 800}} sx={{
              fontSize: {
                xs: 20,
                sm: 80
              }
            }}>Mallows &amp;</Typography>
              <Typography variant="h1" style={{fontWeight: 800}} sx={{
              fontSize: {
                xs: 20,
                sm: 80
              }
            }}>byoa</Typography>
              
              <Typography variant="h1" style={{fontWeight: 800, marginTop: 5, paddingTop: 0}} sx={{
              fontSize: {
                xs: 20,
                sm: 80
              }
            }}>
                <Typography style={{marginLeft: '15%', fontWeight: 800, marginBottom: 0, paddingBottom: 0, paddingRight: 3, backgroundColor: BrandColors.GREEN, color: BrandColors.BLACK}}>powered by</Typography>
                StarkWare
              </Typography>
            </Box>
            <Box style={{flex: 1, textAlign: 'center'}}>
                <img src="https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/610b3e398e603328b9d19d82_mellow-2797.png" 
                  style={{
                    borderRadius: 10,
                    width: '80%',
                    maxWidth: '480px'
                  }} 
                />
            </Box>
          </Box>
        </Box>
        <Container style={{
          marginTop: 10,
          marginBottom: 100
        }}>
          <Box style={{marginBottom: 30}}>
            <Typography variant="h4" style={{textAlign: 'center'}}>
              Mallows and BYOA are applications packaged in NFTs. Apps are secured on L1 and on L2 - powered by Starkware - they are customized and owned by you.
            </Typography>
            <Box style={{marginTop: 20}}>
              <Alert severity="info">Mallows/byoa is currently in phase <code>l2.alpha</code> and runs on the Starknet Alpha Mainnet network. This is a production release of our contract on StarkNet Alpha Mainnet which you can find <Link target="_blank" href="https://voyager.online/contract/0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4">here</Link>. You can learn more on our design decisions and plans <Link href="https://www.mallows.xyz/mallows-l2" target="_blank">here.</Link> <br/><br/>Transactions now incur a gas cost on StarkNet Alpha Mainnet. You will need to bridge eth into your L2 compatible wallet in order to use byoa functionality.</Alert>
            </Box>
          </Box>
          <Box>
            <Box style={{marginBottom: 20}}>
              <Typography variant="h3" style={{fontWeight: 700}}>Available Apps</Typography>
              <Typography variant="body1">The following apps are available on Ethereum Mainnet L1 via the <Link href="https://etherscan.io/address/0x8f15c4ea6ce3fbfc5f7402c5766fc94202704161" style={{color: 'black'}}>BYOA V1 contract.</Link></Typography>
            </Box>
            {isLoadingL1Data && (
              <Box>
                <CircularProgress />
                <Typography variant="caption">Refreshing L1 Data</Typography>
              </Box>
            )}
            <Box style={{marginBottom: 20}}>
              <Grid container spacing={3}>
                {apps.map( (app, i) => (
                  <Grid item xs={6} sm={3} key={`app-card-grid-${i}`}>
                    <ByoaCard 
                      app={app}
                      swo={swo}
                      starkIsConnected={starkIsConnected}
                      isInstalled={l2AppIdLUT[`${app.id}`] !== undefined}
                      onInstalled={loadL2AppData}
                      contractDetails={contractDetails[chosenNetwork]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box>
            <Box style={{marginBottom: 20}}> 
              <Typography variant="h3" style={{fontWeight: 700}}>Your Apps
                  {starkIsConnected && (
                    <Button style={{
                      bottom: -10, position: 'relative',
                      textTransform: 'none'
                    }} onClick={() => {
                      loadL2AppData();
                    }}
                    disabled={isLoadingData}
                    >[ refresh ]</Button>
                  )}
              </Typography>
                {appData.length === 0 && (
                <Typography variant="h6">No apps have been found.</Typography>
              )}
              {isLoadingData && (
                <Box>
                  <CircularProgress />
                  <Typography variant="caption">Refreshing</Typography>
                </Box>
              )}
            </Box>
            
            <Box>
              <Grid container spacing={3}>
                {appData.map( (app, i) => (
                  <Grid item xs={6} sm={3} key={`installed-app-card-grid-${i}`}>
                    <ByoaCard 
                      app={app.ByoaApp}
                      swo={swo}
                      l2Data={app}
                      isInstalled={true}
                      starkIsConnected={starkIsConnected}
                      onInstalled={loadL2AppData}
                      contractDetails={contractDetails[chosenNetwork]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>


         
          <Box>
            {starkIsConnected === false && (
              <Box>
                <Button onClick={() => {
                  connectArgentWallet();
                }}
                  variant='outlined'
                  style={{
                    color: BrandColors.GREEN,
                    backgroundColor: BrandColors.BLACK,
                    borderWidth: 0,
                    fontWeight: 700,
                    textTransform: 'none'
                  }}
                >
                  Connect Argent Wallet
                </Button>
                <br/>
                <Typography variant="caption">Your Wallet is not yet connected. You will need a StarkNet compatible layer 2 wallet from Argent.</Typography>
              </Box>
            )}
            {
              starkIsConnected && (
              <Box>
                <Box>
                  <Typography>Connected Wallet: {starkWalletAddress}</Typography>
                  <Typography variant="caption">To Disconnect your Wallet, use the Argent Extension.</Typography>
                </Box>
                
              </Box>
              )
            }
          </Box>
        </Container>
        
        
      </Box>
  );
}

export default HomePage;
