import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import { BrandColors } from '../utils/constants';
import { Button, Link } from '@mui/material';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
  isStarkConnected: boolean;
  starkWalletAddress?: String;
  connectWallet: () => void;
  onConnect?: () => void;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function HideAppBar(props: Props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar style={{backgroundColor: BrandColors.GREEN, color: BrandColors.BLACK}}>
            <Typography variant="h6" component="div" style={{fontWeight: 700, flexGrow: 1}}>
              Mallows<small style={{fontSize: 10, top: -10, position: 'relative'}}>L2.alpha</small> / StarkNet L2
            </Typography>
            <Typography variant="h6" style={{marginRight: 30}} >
              <Link href="/" style={{
                fontWeight: 'bold',
                color: 'black',
                textDecoration: 'none'
              }}>Home</Link>
            </Typography>
            <Typography variant="h6" style={{marginRight: 30}} >
              <Link target="_blank" href="https://www.mallows.xyz/mallows-l2" style={{
                fontWeight: 'bold',
                color: 'black',
                textDecoration: 'none'
              }}>Learn More</Link>
            </Typography>
            <Typography variant="h6" style={{marginRight: 30}} >
              <Link href="/resources" style={{
                fontWeight: 'bold',
                color: 'black',
                textDecoration: 'none'
              }}>Resources</Link>
            </Typography>
            <Typography variant="h6" style={{marginRight: 30}} >
              <Link href="/launch" style={{
                fontWeight: 'bold',
                color: 'black',
                textDecoration: 'none'
              }}>Launch</Link>
            </Typography>
            <Button variant="outlined" style={{
              color: BrandColors.GREEN,
              backgroundColor: BrandColors.BLACK,
              borderWidth: 0,
              fontWeight: 700,
              textTransform: 'none'
            }}
            onClick={() => {
              if(props.connectWallet) props.connectWallet();
            }}
              
            >{props.isStarkConnected ? `${(props.starkWalletAddress||"").slice(0,5)}...${(props.starkWalletAddress||"").slice((props.starkWalletAddress||"").length-5)}` : 'Connect Wallet'}</Button>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
