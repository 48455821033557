import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { getStarknet } from '@argent/get-starknet';
import { Alert, Box, Button, CircularProgress, Container, createTheme, Grid, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';
import HideAppBar from './components/HideOnScrollAppBar';
import { StarknetWindowObject } from '@argent/get-starknet/dist/extension.model';

import { hash } from 'starknet';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { BrandColors } from './utils/constants';
import ByoaCard from './components/ByoaCard';
import { ByoaApp } from './types/ByoaApp';
import { L2AppData } from './types/L2App';
import { felt_to_str } from './utils/str_to_felt';
import HomePage from './routes/home';
import { Route, Routes } from 'react-router-dom';
import Launch from './routes/launch';
import Resources from './routes/resources';

const fontFamilies = [
  'Chivo',
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createTheme({
  typography: {
    fontFamily: fontFamilies,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamilies
        }
      }
    }
  }
});


var byoaAbi = require('./abi/Byoa.json');
const byoaContractAddress = `0x8f15c4ea6ce3fbfc5f7402c5766fc94202704161`;
const providerNetwork = `https://eth-mainnet.alchemyapi.io/v2/N9hhfuCL7V9y5dXCD5AOddGs-zVIyYc4`;


const contractDetails = {
  'goerli': {
    address: '0x01fa8f8e9063af256155ba4c1442a9994c8f99da84eca99a97f01b2316d1daeb'
  },
  'mainnet': {
    address: '0x071a48d5b8c9ffdd91fd21af1a12816fe420e731e6a776a30214bdc741dc10c4'
  }
};

const chosenNetwork = 'mainnet';

function App() {
  const [swo, setSWO] = useState<StarknetWindowObject | undefined>(undefined);
  const [starkWalletAddress, setStarkWalletAddress] = useState<String | undefined>(undefined);
  const [starkIsConnected, setStarkIsConnected] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [appData, setAppData] = useState<L2AppData[]>([]);
  const [apps, setApps] = useState<ByoaApp[]>([]);
  const [isLoadingL1Data, setIsLoadingL1Data] = useState<boolean>(false);
  const [l2AppIdLUT, setL2AppIdLUT] = useState<any>({});

  const refreshPageData = async () => {
    fetchAvailableApps();
  };

  useEffect( () => {
    refreshPageData();
  }, []);

  useEffect( () => {
    if(swo === undefined) return;
    if(swo?.isConnected !== starkIsConnected) {
      setStarkIsConnected(swo?.isConnected);
    }
  }, [swo?.isConnected]);

  useEffect( () => {
    loadL2AppData();
  }, [starkIsConnected]);

  const connectArgentWallet = async () => {
    try{
      //const starknet = getStarknet({ showModal: true })
      const starknet = getStarknet();
      if(!starknet) {
        throw Error("You did not select a wallet or selection was rejected.");
      }

      await starknet.enable();
      
      console.log("ZZZZ", starknet)
      setSWO(starknet);
      
      const [userWalletContractAddress] = await starknet.enable() // may throws when no extension is detected
      if(userWalletContractAddress.length > 0) {
        setStarkWalletAddress(userWalletContractAddress);
        setStarkIsConnected(starknet.isConnected);
      }

    } catch (error) {
      console.log(`Got Starknet Error: `, error);
    }
  };

  const fetchAvailableApps = async () => {
    setIsLoadingL1Data(true);
    let w3 = new Web3(providerNetwork);
    try {
      let contract = new w3.eth.Contract(byoaAbi['abi'], byoaContractAddress);
      let appIds = await contract.methods.getAppIds().call();

      let allApps = [];
      for(let i = 0; i < appIds.length; i ++) {
        let id : Number = parseInt(appIds[i]);
        // Get the details
        let appDetails = await contract.methods.getAppDetailsById(id).call();
        
        let app : ByoaApp = {
          id: id, 
          name: appDetails[0],
          description: appDetails[1],
          tokenURI: appDetails[2],
          owner: appDetails[3],
          price: parseInt(appDetails[4]),
          address: byoaContractAddress,
          version: 'beta v0.1'
        };

        allApps.push(app);
      }

      setApps(allApps)
      
    } catch( error ) {
      console.log(`Error fetching apps: ${error}`) ;
    } finally {
      setIsLoadingL1Data(false);
    }
  };

  const loadL2AppData = async () => {
    setIsLoadingData(true);
    if(swo) {
      try {

        let tAppData : L2AppData[] = [];
        console.log(swo.signer)
        let getAppLenResult = await swo.provider?.callContract({
          contractAddress: contractDetails[chosenNetwork].address,
          entrypoint: "get_app_len",
          calldata: [ethers.BigNumber.from(swo.selectedAddress).toString()]
        });
        let numberOfApps = ethers.BigNumber.from(getAppLenResult.result[0]).toNumber();
        let tL2AppIdLUT = {};
        for(let i = 0; i < numberOfApps; i ++) {
          let getAppArrayDataByIndexResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_array",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });
          let appIdAtIndex = ethers.BigNumber.from(getAppArrayDataByIndexResult.result[0]).toNumber();

          let isInstalledResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_installation",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });

          let isInstalled = (ethers.BigNumber.from(isInstalledResult.result[0]).toNumber() === 1);
          if(!isInstalled) {
            continue;
          }

          let appParamCountResult = await swo.provider?.callContract({
            contractAddress: contractDetails[chosenNetwork].address,
            entrypoint: "get_app_param_count",
            calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`]
          });

          let configuredAppParams : any = [];
          for(let j = 0; j < ethers.BigNumber.from(appParamCountResult.result[0]).toNumber(); j ++) {
            let appParamValuesByIndexResult = await swo.provider?.callContract({
              contractAddress: contractDetails[chosenNetwork].address,
              entrypoint: "get_app_param_value_array",
              calldata: [ethers.BigNumber.from(swo.selectedAddress).toString(), `${i}`, `${j}`]
            });
            
            configuredAppParams.push({
              ID: felt_to_str(appParamValuesByIndexResult.result[0]),
              Value: felt_to_str(appParamValuesByIndexResult.result[1]),
            });

          }
          // Find the L1 App Details
          for(var z = 0; z < apps.length; z ++) {
            if(apps[z].id === appIdAtIndex) {
              tAppData.push({
                AppId: appIdAtIndex,
                AppIndex: i,
                Params: configuredAppParams,
                Status: 'ACCEPTED',
                IsInstalled: ethers.BigNumber.from(isInstalledResult.result[0]).toNumber() === 1,
                ByoaApp: apps[z]
              });
              break;
            }
          }

          //@ts-expect-error
          tL2AppIdLUT[`${appIdAtIndex}`] = true;
        }

        setAppData(tAppData);
        setL2AppIdLUT(tL2AppIdLUT);

      } catch (error) {
        console.log("Error with resz", error)
      } finally {
        setIsLoadingData(false);
      }
    } else {
      setIsLoadingData(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <HideAppBar
          starkWalletAddress={starkWalletAddress}
          isStarkConnected={starkIsConnected}
          connectWallet={connectArgentWallet}
        ><></></HideAppBar>
        
        <Routes>
          <Route path="/" element={<HomePage 
            swo={swo}
            starkWalletAddress={starkWalletAddress}
            starkIsConnected={starkIsConnected}
            onConnect={(a,b,c) => {
              setSWO(a);
              setStarkWalletAddress(b);
              setStarkIsConnected(c);
            }}
          />} />
          <Route path="/launch" element={<Launch />} />
          <Route path="/resources" element={<Resources />} />
        </Routes>
        
        
      </Box>
      <footer style={{
        backgroundColor: BrandColors.BLACK,
        minHeight: 200
      }}>
        <Container style={{
          color: BrandColors.GREEN,
          fontWeight: 700
        }}>
          <Grid container style={{paddingTop: 30}} >
            <Grid item xs={12} sm={4}>
              <Stack>
                <Typography variant="h5" style={{fontWeight: 'bold'}} >Mallows / Byoa</Typography>
                <Typography variant="h6"><a style={{color: BrandColors.WHITE, textDecoration: 'none'}} href="https://mallows.xyz" target="_blank">mallows.xyz</a></Typography>
                <Typography variant="h6" ><a style={{color: BrandColors.WHITE, textDecoration: 'none'}} href="https://byoa.org" target="_blank">byoa.org</a></Typography>
              </Stack>
            </Grid>
          </Grid>

        </Container>

      </footer>
    </ThemeProvider>
  );
}

export default App;
